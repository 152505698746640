import { Select, Option } from '@contentful/f36-components'

const SwitchNav = () => {
  return (
    <nav className="switch-nav">
      <Select
        onChange={(event) => window.location = (event.target.value)}
      >
        <Option value="">-- Select an Edition --</Option>
        <Option value="https://whfb.app">Index Hub</Option>
        <Option value="https://tow.whfb.app">The Old World</Option>
        <Option value="https://8th.whfb.app">8th Edition</Option>
        <Option value="https://7th.whfb.app">7th Edition</Option>
        <Option value="https://6th.whfb.app">6th Edition</Option>
        <Option value="https://5th.whfb.app">5th Edition</Option>
        <Option value="https://4th.whfb.app">4th Edition</Option>
        <Option value="https://wap.whfb.app">Warhammer Armies Project</Option>
        <Option value="https://9th.whfb.app">Unofficial 9th (WAP v2.2)</Option>
        {/* <optgroup label="Work in Progress"></optgroup> */}
      </Select>
    </nav>
  )
}

export default SwitchNav