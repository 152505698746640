import { useState, useEffect } from 'react'

import { Table } from '@contentful/f36-components'

import RichText from 'components/rich-text'
import { organizeSpecialRules } from 'library/helpers/content'

const ArmourProfile = ({ entry }) => {
  const [specialRules, setSpecialRules] = useState('')
  useEffect(() => {
    if (entry.fields.specialRules) {
      const specialRules = organizeSpecialRules(entry.fields.specialRules)
      if (specialRules.content && specialRules.content.length === 1 && specialRules.content[0].content.length === 0) {
        setSpecialRules('-')
      } else {
        setSpecialRules(<RichText json={specialRules} noWrapper />)
      }
    }
  }, [entry])
  return (
    <div className={`table-wrapper ${entry.fields.slug}`}>
      <Table className="generic-table profile-table profile-table--armour">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Combat</Table.Cell>
            <Table.Cell>Missile</Table.Cell>
            <Table.Cell>Special Rules</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{entry.fields.combat && entry.fields.combat}</Table.Cell>
            <Table.Cell>{entry.fields.missile && entry.fields.missile}</Table.Cell>
            <Table.Cell>{specialRules}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default ArmourProfile