import RichText from 'components/rich-text'

import { Note } from '@contentful/f36-components'

const Notice = ({ entry }) => {
  return (
    <Note
      variant={entry.fields.type}
      className="global-notice"
    >
      <RichText json={entry.fields.body} />
    </Note>
  )
}

export default Notice