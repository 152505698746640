import RichText from './rich-text'

import { organizeSpells } from 'library/helpers/content'
import { removeParens } from 'library/helpers/utilities'

import styles from './army-list.module.scss'

const SpellList = ({ spells = [], className = '' }) => {
  return (
    <>
      {organizeSpells(spells).map(entry => {

        // Set defaults
        let header = removeParens(entry.fields.name)
        let name = header
        let type = entry.fields.type ? entry.fields.type : ''
        let range = entry.fields.range ? entry.fields.range : ''
        let displayDetails = []

        // Set casting/power level
        let castingValuePrefix = 'Casting Value:'
        let castingValueSuffix = '+'
        let castingValue = entry.fields.castingValue ? entry.fields.castingValue : ''
        let castingValueOverride = entry.fields.castingValueOverride ? entry.fields.castingValueOverride : ''
        if (castingValueOverride) {
          castingValue = castingValueOverride
          castingValueSuffix = ''
        }

        if (type) {
          switch (true) {

            // Type is number (string)
            case !/\s/.test(type):
              name = `${type}. ${name}`
              break

            // Power Level
            case type === 'Power Level':
              castingValuePrefix = 'Power:'
              castingValueSuffix = ''
              break

            default:
              displayDetails.push(type)
              break
          }
        }

        // Put it all together
        if (castingValue) displayDetails.push(`${castingValuePrefix} ${castingValue}${castingValueSuffix}`)
        if (range) displayDetails.push(`Range: ${range}`)
        if (process.env.NEXT_PUBLIC_TAG === 'tow') displayDetails = [] // Remove details for TOW
        if (displayDetails.length !== 0) header = `${name} (${displayDetails.join(', ')})`

        /*
        if (type || castingValue) {

          // Check if using power level
          let powerLevel = false
          if (type === 'Power Level') powerLevel = true

          // Casting power
          let castingValueHtml
          // let castingValue = entry.fields.castingValue
          if (castingValue) castingValueHtml = `Casting Value: ${castingValue}+`
          if (powerLevel && castingValue) castingValueHtml = `Power: ${castingValue}`

          // Add range
          // const range = entry.fields.range ? entry.fields.range : ''
          // if (powerLevel && entry.fields.range) castingValueHtml = `${castingValueHtml}${castingValue && range && ', Range: '}${range}`

          // Build
          if (type && entry.fields.type.length === 1) header = `${entry.fields.type}. ${header}`
          header += ` (`
          if (entry.fields.type === 'Lore Attribute') header += entry.fields.type
          if (entry.fields.type === 'Signature Spell') header += `${entry.fields.type}, `
          if (castingValueHtml) header += castingValueHtml
          header += `)`
        }
        */

        return (
          <div key={entry.sys.id} className={styles.spell}>
            <RichText
              json={entry.fields.body}
              header={header}
              className={styles.expandedList}
            />
          </div>
        )
      })}
    </>
  )
}

export default SpellList