/**
 * Node helper scripts
 */

/**
 * Organize associations (alphabetically, ignoring 'the')
 * @param  {arr}  entries
 * @return {obj}
 */
const organizeAssociations = (entries = []) => {

  // Set simple flag based on default entries or simplified index
  let isIndex = false
  if (entries.length && entries[0].href) isIndex = true

  // Get object data based on entry data structure
  const getObjectData = object => {
    if (isIndex) {
      return object.slug
    } else {
      return object.fields.slug
    }
  }

  // Sort
  const sortedItems = [ ...entries ]
  sortedItems.sort(function(a, b) {
    let itemA = getObjectData(a)
    let itemB = getObjectData(b)

    // Remove 'the-' from alphabetizing
    if (itemA.startsWith('the-')) itemA = itemA.substring(4)
    if (itemB.startsWith('the-')) itemB = itemB.substring(4)

    if (itemA < itemB) return -1
    if (itemA > itemB) return 1
    return 0
  })

  // Move magic-items to end of list
  const indexMagicItems = sortedItems.findIndex(item => getObjectData(item) === 'magic-items')
  if (indexMagicItems >= 0) sortedItems.push(sortedItems.splice(indexMagicItems, 1)[0])

  // Move main-rulebook to front of list
  const mainRulebookSlug = process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG ? process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG : 'main-rulebook'
  const indexMainRulebook = sortedItems.findIndex(item => getObjectData(item) === mainRulebookSlug)
  if (indexMainRulebook >= 0) sortedItems.unshift(sortedItems.splice(indexMainRulebook, 1)[0])

  return sortedItems
}

exports.organizeAssociations = organizeAssociations