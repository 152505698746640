import { useContext } from 'react'
import { AppContext } from 'library/context'
import NavIndex from 'library/data/nav-index'
import ArmyRulesNavSection from 'components/army-rules-nav-section'

import { Note } from '@contentful/f36-components'

const ArmyRulesNav = () => {

  // Get nav index
  const { navIndex } = useContext(AppContext)

  // Remove main rulebook
  const mainRulebookSlug = process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG ? process.env.NEXT_PUBLIC_MAIN_RULEBOOK_SLUG : 'main-rulebook'
  const association = navIndex.rulesByArmy.filter(item => item.slug !== mainRulebookSlug)

  // Add note for in-process army book updates
  let note
  if (process.env.NEXT_PUBLIC_ARMIES_UPDATING) {
    note = (
      <Note variant="warning" style={{ marginBottom: '1rem' }}>
        <strong>Work In Progress</strong>
      </Note>
    )
  }
  
  return (
    <nav className="modal-nav">
      {note}
      {association.map(entry => <ArmyRulesNavSection key={entry.id} section={entry} />)}
    </nav>
  )
}

export default ArmyRulesNav