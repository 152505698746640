import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { fetchData } from 'library/helpers/utilities'

import { Button, Badge } from '@contentful/f36-components'
import { EditIcon, CycleIcon } from '@contentful/f36-icons'

const QuickEdit = ({ entryId }) => {

  // Setup
  const [editor, setEditor] = useState(false)
  const [revalKey, setRevalKey] = useState()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const router = useRouter()
  
  // Show editor tools
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') setEditor(true)
    if (localStorage.getItem(`${process.env.NEXT_PUBLIC_TAG}_edit`) || router.asPath.includes(`${process.env.NEXT_PUBLIC_TAG}_edit=1`)) setEditor(true)
    if (localStorage.getItem(`${process.env.NEXT_PUBLIC_TAG}_reval`)) setRevalKey(localStorage.getItem(`${process.env.NEXT_PUBLIC_TAG}_reval`))
    if (router.asPath.includes(`${process.env.NEXT_PUBLIC_TAG}_edit=1`)) localStorage.setItem(`${process.env.NEXT_PUBLIC_TAG}_edit`, 1)
    if (router.asPath.includes(`${process.env.NEXT_PUBLIC_TAG}_secret=1`)) localStorage.setItem(`${process.env.NEXT_PUBLIC_TAG}_secret`, 1)
  }, [])

  // Manual validation
  const handleRevalidation = () => {
    setSuccess(false)
    setError(false)
    setDisabled(true)
    fetchData(`/api/revalidate?id=${revalKey}&path=${router.asPath}`)
    .then(res => {
      if (res.revalidated) {
        setSuccess(true)
      } else {
        setError(true)
      }
    })
    .catch(error => {
      setError(true)
    })
    .then(() => {
      setDisabled(false)
    })
  }
  
  // Hide if not editor
  if (!editor) return null

  // Edit button
  let editButton
  if (entryId) {
    editButton = (
      <a href={`${process.env.NEXT_PUBLIC_EDIT_URL}/${entryId}`}>
        <Button
          variant="primary"
          size="small"
          startIcon={<EditIcon />}
        >
          Edit Entry
        </Button>
      </a>
    )
  }

  // Revalidate button
  let revalButton
  if (revalKey) {
    revalButton = (
      <>
        <Button
          variant="primary"
          size="small"
          startIcon={<CycleIcon />}
          onClick={handleRevalidation}
          isDisabled={disabled}
        >
          Revalidate
        </Button>
        {success && !error ? <Badge variant="positive">SUCCESS</Badge> : null}
        {error ? <Badge variant="negative">ERROR</Badge> : null}
      </>
    )
  }

  return (
    <div className="editor-actions">
      {editButton}
      {revalButton}
    </div>
  )
}

export default QuickEdit