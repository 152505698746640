import { useState, useEffect } from 'react'

import RichText from './rich-text'
import { organizeSpecialRules } from 'library/helpers/content'

import styles from './army-list.module.scss'

const ProfileTable = ({ entry, weapon }) => {

    // Special Rules
  const [specialRules, setSpecialRules] = useState('-')

  // Format
  useEffect(() => {
    if (entry.fields.specialRules) {
      const specialRules = organizeSpecialRules(entry.fields.specialRules, ',\n')
      if (specialRules.content && specialRules.content.length && !specialRules.content[0].content.length) {
        setSpecialRules('-')
      } else {
        setSpecialRules(<RichText json={specialRules} noWrapper />)
      }
    }
  }, [entry])

  return (
    <table className={`${styles.profileTable} ${styles.genericTable}`}>
      <thead>
        <tr>
          <th>{weapon ? 'Range' : 'Combat'}</th>
          <th>{weapon ? 'Strength' : 'Missile'}</th>
          {process.env.NEXT_PUBLIC_TAG === 'tow' && <th>Armour Piercing</th>}
          <th>Special Rules</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {weapon ?
              entry.fields.range && entry.fields.range
              :
              entry.fields.combat && entry.fields.combat
            }
          </td>
          <td>
            {weapon ?
              entry.fields.strength && entry.fields.strength
              :
              entry.fields.missile && entry.fields.missile
            }
          </td>
          {process.env.NEXT_PUBLIC_TAG === 'tow' &&
            <td>{entry.fields.armourPiercing ? entry.fields.armourPiercing : '-'}</td>
          }
          <td>{specialRules}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default ProfileTable