import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import Link from 'components/link'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Table } from '@contentful/f36-components'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import LazyLoadLink from 'components/lazy-load-link'
import LazyLoadBlock from 'components/lazy-load-block'

import { generateEntryLink, generateEntryBlock } from 'library/helpers/content'
import { slugify } from 'library/helpers/utilities'

import MagicItemList from 'components/magic-item-list'
import SpellList from 'components/spell-list'

const RichText = props => {

  // Required
  if (!props.json) return null

  // Get routing object for pathing
  const router = useRouter()

  // Use state so we can manipulate before post-hydration
  const [richTextJson, setRichTextJson] = useState(props.json)
  const [blockHeader, setBlockHeader] = useState(false)

  // Manually append a header object to rich text json
  useEffect(() => {

    // Must have header
    if (richTextJson.content && props.header) {

      // Clone
      const newJson = { ...richTextJson }

      // Get first item
      const firstElement = newJson.content[0]

      // Make sure we haven't already added a header item
      if (firstElement.nodeType === 'paragraph' &&
          firstElement.content.length &&
          firstElement.content[0].nodeType === 'text' &&
          props.header === firstElement.content[0].value.slice(0, -2)
        ) {
        return
      }
      
      // For normal paragraphs, just inline
      if (firstElement.nodeType === 'paragraph' || !firstElement.nodeType) {
        firstElement.content.unshift(generateHeaderObject(props.header))

      // Other block elements, create new line
      } else {
        newJson.content.unshift(generateHeaderObject(props.header, false))
        setBlockHeader(true)
      }

      // Update json
      setRichTextJson(newJson)

    }

  }, [props])

  const options = {
    renderNode: {

      // Rich Text Tables
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div className="table-wrapper">
            <Table className="rich-text-table generic-table">
              <Table.Body>{children}</Table.Body>
            </Table>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => <Table.Row>{children}</Table.Row>,
      [BLOCKS.TABLE_CELL]: (node, children) => {
        const plainText = documentToPlainTextString(node)
        return (
          <Table.Cell className={plainText && plainText.length < 8 ? 'nowrap' : ''}>
            {children}
          </Table.Cell>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        const plainText = documentToPlainTextString(node)
        return (
          <Table.Cell className={`rich-text-table__th ${plainText && plainText.length < 8 ? 'nowrap' : ''}`}>
            {children}
          </Table.Cell>
        )
      },

      // Render images
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <figure className="embedded-asset" style={{ width: `${node.data.target.fields.file.details.image.width}px` }}>
            <img src={`${node.data.target.fields.file.url}?fm=jpg&fl=progressive`}
              alt={node.data.target.fields.title}
              width={node.data.target.fields.file.details.image.width}
            />
            {node.data.target.fields.description && <figcaption>{node.data.target.fields.description}</figcaption>}
          </figure>
        )
      },

      // Render embedded block by type
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {

        // Preloaded lists
        if (node.data.magicItem) return <MagicItemList preload={node.data.magicItem} />
        if (node.data.spell) return <SpellList preload={node.data.spell} />

        // Single entry spells/items
        if (node.data.target.sys.contentType) {
          if (node.data.target.sys.contentType.sys.id === 'magicItem') return <MagicItemList preload={[node.data.target]} />
          if (node.data.target.sys.contentType.sys.id === 'spell') return <SpellList preload={[node.data.target]} />
          if (node.data.target.sys.contentType.sys.id === 'rule') return generateEntryBlock(node.data.target)
        }

        // Lazy load block with minimal data
        if (!node.data.target.sys.contentType) return <LazyLoadBlock id={node.data.target.sys.id} />

        // Otherwise, build block as provided
        return generateEntryBlock(node.data.target)

      },

      // Render inline hyperlinks as links by type
      [INLINES.ENTRY_HYPERLINK]: (node) => {

        // Ignore links
        if (props.ignoreLinks) return node.content[0].value

        // Wrapper classes
        let classes = ''
        if (node.content[0].marks.length) {
          classes = node.content[0].marks.map(mark => mark.type).join(' ')
        }

        // If no entry, return lazy loaded link
        if (!node.data.target.fields) {
          return (
            <LazyLoadLink
              id={node.data.target.sys.id}
              linkText={node.content[0].value}
              deep={props.deepLinks}
              classes={classes}
            />
          )
        }

        // Otherwise, build link as provided
        return generateEntryLink(node.data.target, node.content[0].value, props.deepLinks, classes)

        // If not enough data to build a link return plain text
        if (!node.data.target.fields && !node.data.target.sys.contentType) {
          return <span className={`no-link ${classes}`}>{node.content[0].value}</span>
        }
      },

      // Inline assets
      [INLINES.ASSET_HYPERLINK]: (node) => {
        return (
          <Link href={`https:${node.data.target.fields.file.url}`}>
            {node.content[0].value}
          </Link>
        )
      },

      // Add ID's to heading tags
      [BLOCKS.HEADING_2]: (node) => {
        const slug = slugify(node.content[0].value)
        return <h2 id={slug} className="anchor" onClick={() => { router.push(`#${slug}`) }}>{node.content[0].value}</h2>
      },
      [BLOCKS.HEADING_3]: (node) => {
        const slug = slugify(node.content[0].value)
        return <h3 id={slug} className="anchor" onClick={() => { router.push(`#${slug}`) }}>{node.content[0].value}</h3>
      }
    },
    renderText: (text) => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }

  // Wrapper classes
  const className = props.className ? props.className : ''

  // Display with or without wrapper
  let content = props.json ? documentToReactComponents(props.json, options) : null
  if (!props.noWrapper) {
    content = (
      <article className={`article--rich-text ${className}`}>
        {props.json ? documentToReactComponents(props.json, options) : null}
      </article>
    )
  }

  return (
    <>
      {content}
    </>
  )
}

export default RichText

/**
 * Generate a rich text friendly item for headers
 * @param  {str}  string
 * @param  {bool} inline
 * @return {ob}
 */
const generateHeaderObject = (string, inline = true) => {

  let header = {
    "nodeType": "text",
    "value": `${string}: `,
    "marks": [
      {
        "type": "bold"
      }
    ],
    "data": {}
  }

  if (!inline) {
    header = {
      "nodeType": "paragraph",
      "data": {},
      "content": [ header ]
    }
  }

  return header
}