import { useState, useEffect } from 'react'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { Table } from '@contentful/f36-components'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import ProfileTable from './profile-table'
import DisplayTable from './display-table'
import SpellList from './spell-list'
import MagicItemList from './magic-item-list'

import { generateEntryBlock } from 'library/helpers/content'

import styles from './army-list.module.scss'

const RichText = props => {

  // Required
  if (!props.json) return null

  // Use state so we can manipulate before post-hydration
  const [richTextJson, setRichTextJson] = useState(props.json)
  const [blockHeader, setBlockHeader] = useState(false)

  // Manually append a header object to rich text json
  useEffect(() => {

    // Must have header
    if (richTextJson.content && props.header) {

      // Clone
      const newJson = { ...richTextJson }

      // Get first item
      const firstElement = newJson.content[0]

      // Make sure we haven't already added a header item
      if (firstElement.nodeType === 'paragraph' &&
          firstElement.content.length &&
          firstElement.content[0].nodeType === 'text' &&
          props.header === firstElement.content[0].value.slice(0, -2)
        ) {
        return
      }

      // For normal paragraphs, just inline
      if (firstElement.nodeType === 'paragraph' || !firstElement.nodeType) {
        firstElement.content.unshift(generateHeaderObject(props.header))

      // Other block elements, create new line
      } else {
        newJson.content.unshift(generateHeaderObject(props.header, false))
        setBlockHeader(true)
      }

      // Update json
      setRichTextJson(newJson)

    }

  }, [props])

  const options = {
    renderNode: {

      // Rich Text Tables
      [BLOCKS.TABLE]: (node, children) => {
        return (
          <div className={`${styles.tableWrapper}`}>
            <table className={`${styles.chartTable} ${styles.genericTable}`}>
              <tbody>{children}</tbody>
            </table>
          </div>
        )
      },
      [BLOCKS.TABLE_ROW]: (node, children) => <Table.Row>{children}</Table.Row>,
      [BLOCKS.TABLE_CELL]: (node, children) => {
        const plainText = documentToPlainTextString(node)
        return (
          <td className={plainText && plainText.length < 8 ? styles.nowrap : ''}>
            {children}
          </td>
        )
      },
      [BLOCKS.TABLE_HEADER_CELL]: (node, children) => {
        const plainText = documentToPlainTextString(node)
        return (
          <th className={`rich-text-table__th ${plainText && plainText.length < 8 ? styles.nowrap : ''}`}>
            {children}
          </th>
        )
      },

      // Render images
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        return (
          <figure className={styles.embeddedAsset}>
           <img src={`${node.data.target.fields.file.url}`}
              width={node.data.target.fields.file.details.image.width}
              className={styles.image}
            />
            {node.data.target.fields.description && <figcaption>{node.data.target.fields.description}</figcaption>}
          </figure>
        )
      },

      // Render embedded block by type
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {

        const entry = node.data.target
        const type = entry.sys && entry.sys.contentType ? entry.sys.contentType.sys.id : null
        switch (type) {
          case 'armourProfile':
            return <ProfileTable entry={entry} />
            break
          case 'weaponProfile':
            return <ProfileTable entry={entry} weapon />
            break
          case 'chart':
            return <DisplayTable entry={entry} config={entry.fields.chart ? entry.fields.chart : null} />
            break
          default:

            // Embedded lists
            if (node.data.magicItem) return <MagicItemList magicItems={node.data.magicItem} />
            if (node.data.spell) return <SpellList spells={node.data.spell} />

            // Single entry spells/items
            if (node.data.target.sys.contentType) {
              if (node.data.target.sys.contentType.sys.id === 'magicItem') return <MagicItemList magicItems={[node.data.target]} />
              if (node.data.target.sys.contentType.sys.id === 'spell') return <SpellList spells={[node.data.target]} />
              if (node.data.target.sys.contentType.sys.id === 'rule') return generateEntryBlock(node.data.target, true)
            }

            return '[INVALID]'
            break
        }

      },

      // Render inline hyperlinks
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        
        // Spells should be italicized
        const entry = node.data.target
        const type = entry.sys && entry.sys.contentType ? entry.sys.contentType.sys.id : null
        if (type && type === 'spell') return <em>{node.content[0].value}</em>
        
        // Everything else
        return node.content[0].value
      },

      // Inline assets
      [INLINES.ASSET_HYPERLINK]: (node) => node.content[0].value

    },
    // renderText: (text) => text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
    renderText: (text) => {
      if (props.abbreviate) text = text.replaceAll('point', 'pt')
      return text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
    }
  }

  // Wrapper classes
  const className = props.className ? props.className : ''

  // Display with or without wrapper
  let content = richTextJson ? documentToReactComponents(richTextJson, options) : null
  if (!props.noWrapper) {
    content = (
      <div className={`${styles.richText} ${blockHeader ? styles.blockHeader : ''} ${className}`}>
        {content}
      </div>
    )
  }

  return content

}

export default RichText

/**
 * Generate a rich text friendly item for headers
 * @param  {str}  string
 * @param  {bool} inline
 * @return {ob}
 */
const generateHeaderObject = (string, inline = true) => {

  let header = {
    "nodeType": "text",
    "value": `${string}: `,
    "marks": [
      {
        "type": "bold"
      }
    ],
    "data": {}
  }

  if (!inline) {
    header = {
      "nodeType": "paragraph",
      "data": {},
      "content": [ header ]
    }
  }

  return header
}