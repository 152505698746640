import IndexList from 'components/index-list'
import { generateEntryLink } from 'library/helpers/content'

const RelatedLinks = ({ entry }) => {

  // Required
  if (!entry) return null

  // Base vars
  let relatedLinks
  let sectionLinks

  // Get primary link
  if (entry.fields.relatedLinks) {

    // Get links and primary links
    const links = [ ...entry.fields.relatedLinks ]
    const primaryLink = links[0]

    // If related links are the same type and the primary link is a smaller order, assume it is the parent
    if (primaryLink.fields.ruleType[0].sys.id === entry.fields.ruleType[0].sys.id && primaryLink.fields.order < entry.fields.order) {
      sectionLinks = (
        <div className="section-link">
          <span className="section-link__header">Parent:</span>
          {generateEntryLink(primaryLink, primaryLink.fields.name)}
        </div>
      )
      links.shift() // Remove from related list
    }

    // If any links remain, add them as an index
    if (links.length > 0) {

      // If only one remains, display inline
      let relatedLinksList
      if (links.length === 1) {
        relatedLinksList = generateEntryLink(links[0], links[0].fields.name)
      } else {
        relatedLinksList = <IndexList entries={links} organize={false} />
      }

      relatedLinks = (
        <div className="related-links">
          <span className={`related-links__header `}>Related:</span>
          {relatedLinksList}
        </div>
      )
    }
  }

  return (
    <>
      {sectionLinks}
      {relatedLinks}
    </>
  )
}

export default RelatedLinks