import { useContext } from 'react'
import { AppContext } from 'library/context'
import MainRulebookNavSection from 'components/main-rulebook-nav-section'

const MainRulebookNav = () => {

  // Get nav index
  const { navIndex } = useContext(AppContext)

  return (
    <nav className="modal-nav">
      {navIndex.rulesByType.map(entry => <MainRulebookNavSection key={entry.id} section={entry} />)}
    </nav>
  )
}

export default MainRulebookNav