import { useState } from 'react'
import Link from 'components/link'
import { useWindowSize } from 'library/hooks/useWindowSize'
import { trackEvent } from 'library/helpers/ga'

import {
  Modal,
  Button,
  Select,
  Option
} from '@contentful/f36-components'
import { ExternalLinkIcon } from '@contentful/f36-icons'

import ReportBugForm from 'components/report-bug-form'
import SwitchNav from 'components/switch-nav'

const Footer = props => {

  // Set display state
  const [showBugReportModal, setShowBugReportModal] = useState(false)
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [showSwitchModal, setShowSwitchModal] = useState(false)

  // Use hooks to watch for window resize/scroll
  const [windowWidth] = useWindowSize()

  // Show modal and report
  const handleBugReport = () => {
    trackEvent({ action: "reportBugOpen" })
    setShowBugReportModal(true)
  }

  // Show modal and discord
  const handleSupport = () => {
    trackEvent({ action: "supportOpen" })
    setShowSupportModal(true)
  }

  // Show modal and switcher
  const handleSwitch = () => {
    trackEvent({ action: "switchOpen" })
    setShowSwitchModal(true)
  }

  // Build report bug modal
  const modalReportBug = (
    <Modal
      isShown={showBugReportModal}
      onClose={() => setShowBugReportModal(false)}
      title="Report Bug"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <ReportBugForm />
    </Modal>
  )

  // Build Discord modal
  const modalSupport = (
    <Modal
      isShown={showSupportModal}
      onClose={() => setShowSupportModal(false)}
      title="Support the Project"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <h5 style={{ margin: '0' }}>Discord</h5>
      <p>Did you notice something was missing? Maybe you'd like to contribute to the Warhammer Fantasy Online Rules Index Project, or just offer feedback. This Discord server is to allow the community to reach out.</p>
      <p>
        <Button
          as="a"
          target="_blank"
          rel="noreferrer noopener"
          href={process.env.NEXT_PUBLIC_DISCORD_INVITE}
          variant="primary"
        >
          Join Discord Server
        </Button>
      </p>
      <hr style={{ margin: '1.5rem 0' }} />
      <h5 style={{ margin: '0' }}>Server Costs</h5>
      <p>The mission is to keep the Warhammer Fantasy Online Rules Index Project available to everyone and ad free. However, with it's growing popularity the cost to maintain it's servers has also increased. If you would like to support the project, please consider donating.</p>
      <p>
        <Button
          as="a"
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.paypal.com/donate/?hosted_button_id=MSAXCPFM4Y2LC"
          variant="primary"
          endIcon={<ExternalLinkIcon />}
        >
          Donate
        </Button>
      </p>
    </Modal>
  )

  // Build switch modal
  const modalSwitch = (
    <Modal
      isShown={showSwitchModal}
      onClose={() => setShowSwitchModal(false)}
      title="Navigate to Another Index"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <SwitchNav />
    </Modal>
  )

  return (
    <footer>
      <a onClick={handleSwitch}>Switch Index</a>
      {modalSwitch}
      <Link href="/links" prefetch={false}>
        Links
      </Link>
      <a href="https://whfb.app/credit" target="_blank" rel="noopener noreferrer">
        Credit
      </a>
      <Link href="/sitemap" prefetch={false}>
        Sitemap
      </Link>
      <a onClick={handleSupport}>Support</a>
      {modalSupport}
      <a onClick={handleBugReport}>Report Bug</a>
      {modalReportBug}
    </footer>
  )
}

export default Footer