import { useState, useEffect, useContext, useRef } from 'react'
import { AppContext } from 'library/context'
import { useRouter } from 'next/router'
import { buildQueryString } from 'library/helpers/utilities'
import NavIndex from 'library/data/nav-index'

import {
  Flex,
  TextInput,
  IconButton,
  TextLink,
  Select,
  Option,
  Switch,
  Spinner,
  Button
} from '@contentful/f36-components'
import { PlusCircleIcon, SearchIcon } from '@contentful/f36-icons'

const SearchForm = ({ inputRef }) => {

  // Get nav index
  const { navIndex } = useContext(AppContext)
  
  // Set input state
  const [search, setSearch] = useState('')
  const [showOptions, setShowOptions] = useState(false)
  const [association, setAssociation] = useState()
  const [toggleRules, setToggleRules] = useState(true)
  const [toggleUnit, setToggleUnit] = useState(true)
  const [toggleSpell, setToggleSpell] = useState(true)
  const [toggleMagicItem, setToggleMagicItem] = useState(true)

  // Input ref
  if (!inputRef) inputRef = useRef(null)

  // Get router for push to search page
  const router = useRouter()

  // Add search query terms
  const handleSubmitSearch = event => {
    event.preventDefault()
    const searchParams = {}

    // Get selected types
    let typeArray = []
    if (toggleRules) typeArray.push('rules')
    if (toggleUnit) typeArray.push('unit')
    if (toggleSpell) typeArray.push('spell')
    if (toggleMagicItem) typeArray.push('magicItem')

    // Append types if less than all, id and redirect
    if (typeArray.length !== 4) searchParams.type = typeArray.join(',')
    if (association) searchParams.id = association
    searchParams.show = showOptions
    router.push(buildQueryString(`/search/${search}`, searchParams))
  }

  // Reset seach form
  const handleSearchReset = () => {
    inputRef.current.focus()
    setSearch('')
    setAssociation('')
    setToggleRules(true)
    setToggleUnit(true)
    setToggleSpell(true)
    setToggleMagicItem(true)
  }

  // Toggle search types
  const handleSwitchType = type => {
    switch (type) {
      case 'unit':
        setToggleUnit(!toggleUnit)
        break
      case 'spell':
        setToggleSpell(!toggleSpell)
        break
      case 'magicItem':
        setToggleMagicItem(!toggleMagicItem)
        break
      default:
        setToggleRules(!toggleRules)
        break
    }
  }

  // Get preselected results from query string if available
  useEffect(() => {

    // Search
    if (router.query.query) setSearch(router.query.query)

    // Type
    if (router.query.type) {

      // Extract
      const types = router.query.type.split(',')

      // Set all intially to false
      setToggleUnit(false)
      setToggleSpell(false)
      setToggleMagicItem(false)
      setToggleRules(false)

      // For each available, set to true
      for (const type of types) {
        switch (type) {
          case 'unit':
            setToggleUnit(true)
            break
          case 'spell':
            setToggleSpell(true)
            break
          case 'magicItem':
            setToggleMagicItem(true)
            break
          case 'rules':
            setToggleRules(true)
            break
        }
      }
    }

  }, [router.query])

  // Set association default, if applicable
  useEffect(() => {
    if (router.query.id) {
      setAssociation(router.query.id)
    }
  }, [router.query, navIndex.rulesByArmy])

  // Hide/show adv options if applicable
  useEffect(() => {
    if (router.query.show === 'false') setShowOptions(false)
    if (router.query.show === 'true') setShowOptions(true)
  }, [router.query])

  // Margin spacing
  const defaultSpacing = 'spacingL'

  // Build select navigation
  const selectNav = (
    <Select
      width="auto"
      value={association}
      onChange={(event) => setAssociation(event.target.value)}
    >
      <Option value="">-- Specify Association --</Option>
      {navIndex.rulesByArmy.map(entry => {
        return (
          <Option
            key={entry.id}
            value={entry.id}
          >
            {entry.name}
          </Option>
        )
      })}
    </Select>
  )

  // Build advanced options
  const advancedOptions = (
    <>
      {navIndex.rulesByArmy.length === 0
        ? <Spinner size="large" />
        : <div className="search__advanced">
            {process.env.NEXT_PUBLIC_ARMIES_ENABLED && selectNav}
            <div className="search__advanced--switches">
              <Switch
                isChecked={toggleRules}
                id="rules"
                onChange={() => handleSwitchType('rules')}
              >
                Include Rules
              </Switch>
              {process.env.NEXT_PUBLIC_ARMIES_ENABLED && 
                <Switch
                  isChecked={toggleUnit}
                  id="unit"
                  onChange={() => handleSwitchType('unit')}
                >
                  Include Units
                </Switch>
              }
              <Switch
                isChecked={toggleSpell}
                id="spell"
                onChange={() => handleSwitchType('spell')}
              >
                Include Spells
              </Switch>
              <Switch
                isChecked={toggleMagicItem}
                id="magicItem"
                onChange={() => handleSwitchType('magicItem')}
              >
                {`Include Magic Items${process.env.NEXT_PUBLIC_ARMIES_ENABLED ? ' (and Abilities)' : ''}`}
              </Switch>
            </div>
            <Button
              className="search__advanced--submit"
              variant="primary"
              onClick={handleSubmitSearch}
              isDisabled={search ? false : true}
            >
              Search
            </Button>
          </div>
        }
    </>
  )

  return (
    <>
      <form onSubmit={handleSubmitSearch}>
        <Flex
          alignItems="center"
          marginTop={defaultSpacing}
          marginBottom={defaultSpacing}
          className="search-form__input-wrapper"
        >
          <TextInput
            onChange={(event) => setSearch(event.target.value)}
            value={search}
            type="text"
            placeholder="Enter search terms here..."
            className="search-form__input"
            ref={inputRef}
          />
          <IconButton
            className="search-form__submit ghost-button"
            icon={<SearchIcon variant="primary" size="large" />}
            onClick={handleSubmitSearch}
            isDisabled={search ? false : true}
          />
          {search && <PlusCircleIcon
            className="search-form__clear"
            variant="muted"
            size="medium"
            onClick={handleSearchReset}
          />}
        </Flex>
      </form>
      <Flex marginTop="spacingL" marginBottom="spacingL">
        <TextLink onClick={() => setShowOptions(!showOptions)}>
          Toggle Advanced Search Options
        </TextLink>
      </Flex>
      {showOptions && advancedOptions}
    </>
  )
}

export default SearchForm