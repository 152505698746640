/**
 * Bypass default next Link component to stop prefetchin
 */

import { useRouter } from 'next/router'

const Link = ({ href, className, children }) => {
  
  // Set router
  const router = useRouter()

  // User router to push
  const handleClick = (event, href) => {
    event.preventDefault()
    if (event.metaKey) {
      window.open(href, '_blank')
    } else {
      router.push(href)
    }
  }

  return (
    <a
      href={href}
      onClick={(event) => handleClick(event, href)}
      {...(className && { className: `${className}` })}
    >
      {children}
    </a>
  )
}

export default Link