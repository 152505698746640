import Spell from 'components/spell'

import { organizeSpells } from 'library/helpers/content'

const SpellList = ({ entry, preload = [] }) => {

  // For display
  let displayItems = []

  if (preload.length > 0) {
    displayItems = organizeSpells(preload)
    return <div className="spell-list">{displayItems.map(entry => <Spell key={entry.sys.id} entry={entry} />)}</div>

  } else {
    return null
  }
}

export default SpellList