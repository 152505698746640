import { Table } from '@contentful/f36-components'

import RichText from 'components/rich-text'
import DetailedLink from 'components/detailed-link'
import { organizeSpecialRules, organizeMagicItems } from 'library/helpers/content'
import { removeParens } from 'library/helpers/utilities'

const UnitProfile = ({ entry }) => {

  /**
   * Single Display Items
   */

  // Unit profile rows
  let profileRows = []
  if (entry.fields && entry.fields.unitProfile) {
    profileRows = entry.fields.unitProfile
  }
  if (entry.fields && entry.fields.additionalEntries) {
    for (const row of entry.fields.additionalEntries) {
      if (row.fields.unitProfile) {
        profileRows = [ ...profileRows, ...row.fields.unitProfile ]
      }
      if (row.fields.supportEntry && row.fields.additionalEntries) {
        for (const entry of row.fields.additionalEntries) {
          if (entry.fields && entry.fields.unitProfile) {
            profileRows = [ ...profileRows, ...entry.fields.unitProfile ]
          }
        }
      }
    }
  }

  // TEMP: If unit, appends name value
  if (entry.sys.contentType.sys.id === 'unit' && entry.fields.statistics) {
    const temp = { ...entry.fields.statistics }
    temp.Name = entry.fields.name
    profileRows.push(temp)
  }

  // Set version flags
  const isTow = process.env.NEXT_PUBLIC_TAG === 'tow' ? true : false
  const is4th = process.env.NEXT_PUBLIC_TAG === '4th' ? true : false

  // Profile table
  let profileTable
  if (profileRows.length > 0) {
    profileTable = (
      <Table className="generic-table unit-profile-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>M</Table.Cell>
            <Table.Cell>WS</Table.Cell>
            <Table.Cell>BS</Table.Cell>
            <Table.Cell>S</Table.Cell>
            <Table.Cell>T</Table.Cell>
            <Table.Cell>W</Table.Cell>
            <Table.Cell>I</Table.Cell>
            <Table.Cell>A</Table.Cell>
            <Table.Cell>Ld</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {profileRows.map((row, index) => {
            return (
               <Table.Row key={index}>
                <Table.Cell>{row.Name}</Table.Cell>
                <Table.Cell>{row.M}</Table.Cell>
                <Table.Cell>{row.WS}</Table.Cell>
                <Table.Cell>{row.BS}</Table.Cell>
                <Table.Cell>{row.S}</Table.Cell>
                <Table.Cell>{row.T}</Table.Cell>
                <Table.Cell>{row.W}</Table.Cell>
                <Table.Cell>{row.I}</Table.Cell>
                <Table.Cell>{row.A}</Table.Cell>
                <Table.Cell>{row.Ld}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }

  // Build unit category
  let unitCategoryDisplay
  let unitCategoryHtml
  if (entry.fields.unitCategory) {
    const unitCategory = entry.fields.unitCategory[0].fields
    const unitCategoryRule = unitCategory.rule && unitCategory.rule[0].fields ? unitCategory.rule[0].fields : ''
    if (unitCategory && unitCategoryRule) {
      let parent = unitCategoryRule.ruleType[0].fields && unitCategoryRule.ruleType[0].fields.slug ? unitCategoryRule.ruleType[0].fields.slug : ''
      let child = unitCategoryRule.slug
      if (parent && child) {
        unitCategoryHtml = (
          <DetailedLink
            text={unitCategory.name}
            href={`/${parent}/${child}`}
            entryId={unitCategory.rule[0].sys.id}
            deep
          />
        )
      }
    } else {
      unitCategoryHtml = <span className="unit-profile__details__link">{unitCategory.name}</span>
    }
    if (unitCategoryHtml) {
      unitCategoryDisplay = (
        <div className="unit-profile__details--unit-category">
          <strong>Unit Category:</strong>&nbsp;
          <span className="unit-profile__details__link">
            {unitCategoryHtml}
          </span>
        </div>
      )
    }
  }

  // Cost
  let cost
  if (entry.fields.cost && entry.fields.cost > 0) cost = `${entry.fields.cost} points`
  if (cost && entry.fields.unitSize && entry.fields.unitSize !== '1') cost = `${cost} per model`
  if (entry.fields.costOverride) cost = entry.fields.costOverride
  if (cost) cost = <div className="unit-profile__details--points"><strong>Cost: </strong>{cost}</div>

  // Base size
  let baseSize
  if (entry.fields.baseSize) baseSize = (
    <div className="unit-profile__details--base-size">
      <strong>Base Size:</strong> {entry.fields.baseSize}
    </div>
  )

  // Unit size
  let unitSize
  if (entry.fields.unitSize) unitSize = (
    <div className="unit-profile__details--unit-size">
      <strong>Unit Size:</strong> {entry.fields.unitSize}
    </div>
  )

  // Armour value
  let armourValue
  if (entry.fields.armourValue) armourValue = (
    <div className="unit-profile__details--armour-value">
      <strong>Armour Value:</strong> {entry.fields.armourValue}
    </div>
  )

  // Options
  let options
  if (entry.fields.options) options = (
    <div className="unit-profile__details--option">
      <RichText
        json={entry.fields.options}
        header="Options"
        deepLinks
        noWrapper
      />
    </div>
  )

  // Army Lists
  let armyLists
  if (entry.fields.armyLists && entry.fields.armyLists.length > 0) {
    armyLists = (
      <div className="unit-profile__details--unit-size">
        <strong>Army Lists: </strong>
        {entry.fields.armyLists.map(item => {
          return (
            <span key={item.id} className="unit-profile__details__link">
              <DetailedLink
                text={item.name}
                href={item.href}
                entryId={item.id}
              />
            </span>
          )
        })}
      </div>
    )
  }

  // LEGACY
  // Build related units
  let relatedUnits
  if (entry.fields.relatedUnits) {
    let i = 0
    const relatedLinks = entry.fields.relatedUnits.map(entry => {
      i++
      return (
        <span
          key={entry.sys.id}
          className="unit-profile__details__link"
        >
          <DetailedLink
            text={entry.fields.name}
            href={`/unit/${entry.fields.slug}`}
            entryId={entry.sys.id}
            deep
          />
        </span>
      )
    })
    relatedUnits = (
      <p>
        <strong>Related Units:</strong>&nbsp;
        {relatedLinks}
      </p>
    )
  }



  /**
   * Multiple Display Items
   */
  
  // Get all entries first
  let entries = [ entry ]
  if (entry.fields.additionalEntries) {
    entries = [ ...entries, ...entry.fields.additionalEntries.filter(item => !item.fields.supportEntry) ]
  }
  const multi = entries.length > 1 ? true : false

  // Set all display vars
  let troopTypeDisplay = []
  let magicRules = []
  let specialRules = []
  let optionalRules = []
  let equipment = []
  let magicItems = []

  for (const entry of entries) {

    // Unit name
    const name = removeParens(entry.fields.nameSingular)


    // Troop type
    if (entry.fields.troopType) {
      const items = []
      for (const type of entry.fields.troopType) {
        let troopTypeName = type.fields?.name
        if (!troopTypeName) continue;
        let parent = type.fields.rule && type.fields.rule[0].fields && type.fields.rule[0].fields.ruleType[0].fields ? type.fields.rule[0].fields.ruleType[0].fields.slug : null
        if (parent) {        
          let child = type.fields.rule[0].fields.slug
          items.push(
            <span key={type.sys.id} className="unit-profile__details__link">
              <DetailedLink
                text={troopTypeName}
                href={`/${parent}/${child}`}
                entryId={type.fields.rule[0].sys.id}
                deep
              />
            </span>
          )
        } else {
          items.push(<span key={type.sys.id} className="unit-profile__details__link">{troopTypeName}</span>)
        }
      }
      let headerTroopType = 'Troop Type'
      if (multi && name) headerTroopType = `${headerTroopType} (${name})`
        if (items && items.length > 0) {
        troopTypeDisplay.push(
          <div className="unit-profile__details--troop-type" key={`troop-type-${entry.sys.id}`}>
            <strong>{headerTroopType}:</strong>&nbsp;
            {items}
          </div>
        )
      }
    }
   

    // Magic
    let headerMagic = 'Magic'
    if (multi) headerMagic = `${headerMagic} (${name})`

    // Ignore if override set
    if (entry.fields.magicOverride) {
      magicRules.push(
        <div className="unit-profile__details--magic-rule" key={`magic-${entry.sys.id}`}>
          <RichText
            json={entry.fields.magicOverride}
            header={headerMagic}
            deepLinks
          />
        </div>
      )

    // Else build list of lores with level
    } else if (entry.fields.magicLore) {
      let i = 0
      let total = entry.fields.magicLore.length
      let magicLores

      // If wizard level is set, we'll build friendly description
      const wizardLevel = entry.fields.wizardLevel

      // Build list of lores as links
      let magicLoreLinks = entry.fields.magicLore.map(item => {

        // Set vars
        i++
        let name = item.fields.name
        if (item.fields.name.substr(item.fields.name.length - 5).toLowerCase() === ' lore') name = name.slice(0, -5)
        if (wizardLevel) name = name.replace('The ', 'the ')
        let parent = item.fields.rule.fields ? item.fields.rule.fields.ruleType[0].fields.slug : process.env.NEXT_PUBLIC_MAGIC_RULETYPE
        let child = item.fields.rule.fields ? item.fields.rule.fields.slug : item.fields.slug.slice(0, -5)

        return (
          <>
            {wizardLevel && i === total && 'or '}
            <DetailedLink
              key={item.sys.id}
              text={name}
              href={`/${parent}/${child}`}
              entryId={item.fields.rule.sys.id}
              deep
            />
            {wizardLevel && i !== total && ', '}
          </>
        )
      })

      // Wrap lores in spans
      if (!wizardLevel) {
        magicLores = magicLoreLinks.map((link, index) => {
          return (
            <span
              key={index}
              className="unit-profile__details__link"
            >
              {link}
            </span>
            )
        })

      // Write magic rule
      } else {
        let level = `is a Level ${wizardLevel} Wizard who`
        if (wizardLevel === '0') level = `that is a Wizard`
        magicLores = <>{removeParens(entry.fields.nameSingular)} {level} uses spells from {magicLoreLinks}.</>
      }

      magicRules.push(
        <div className="unit-profile__details--magic-lore" key={`magic-${entry.sys.id}`}>
          <strong>{headerMagic}:</strong>&nbsp;
          {magicLores}
        </div>
      )
    }


    // Special Rules
    if (entry.fields.specialRules) {
      let headerSpecialRules = 'Special Rules'
      if (multi && name) headerSpecialRules = `${headerSpecialRules} (${name})`
      specialRules.push(
        <div className="unit-profile__details--special-rules unit-profile__details--link-list" key={`special-rules-${entry.sys.id}`}>
          <RichText
            json={organizeSpecialRules(entry.fields.specialRules)}
            header={headerSpecialRules}
            deepLinks
            noWrapper
          />
        </div>
      )
    }


    // Optional Rules
    if (entry.fields.optionalRules) {
      let headerOptionalRules = 'Optional Rules'
      if (multi && name) headerOptionalRules = `${headerOptionalRules} (${name})`
      optionalRules.push(
        <div className="unit-profile__details--optional-rules unit-profile__details--link-list" key={`optional-rules-${entry.sys.id}`}>
          <RichText
            json={organizeSpecialRules(entry.fields.optionalRules)}
            header={headerOptionalRules}
            deepLinks
            noWrapper
          />
        </div>
      )
    }

    // Equipment
    // Note, equipment list method is deprecated in lieu of just listing options
    if (entry.fields.equipment) {
      let headerEquipment = 'Equipment'
      if (multi && name) headerEquipment = `${headerEquipment} (${name})`
      let equipmentData
      equipmentData = entry.fields.equipment
      // equipmentData = organizeSpecialRules(entry.fields.equipment, '', '', true) // Deprecated
      if (equipmentData) {
        equipment = (
          <div className={`unit-profile__details--equipment`} key={`equipment-${entry.sys.id}`}>
            <RichText
              json={equipmentData}
              header={headerEquipment}
              deepLinks
              noWrapper
            />
          </div>
        )
      }
    }

    // Magic Items
    if (entry.fields.magicItem) {
      let i = 0
      const magicItemLinks = organizeMagicItems(entry.fields.magicItem).map(item => {
        i++
        return (
          <span
            key={item.sys.id}
            className="unit-profile__details__link"
          >
            <DetailedLink
              text={item.fields.name}
              href={`/magic-item/${item.fields.slug}`}
              entryId={item.sys.id}
              deep
            />
          </span>
        )
      })
      let headerMagicItems = 'Magic Items / Abilities'
      if (multi && name) headerMagicItems = `${headerMagicItems} (${name})`
      magicItems.push(
        <div className="unit-profile__details--magic-items" key={`magic-items-${entry.sys.id}`}>
          <strong>{headerMagicItems}:</strong>&nbsp;
          {magicItemLinks}
        </div>
      )
    }
  }

  return (
    <div className={`unit-profile ${entry.fields.slug}`}>
      <div className={`table-wrapper ${entry.fields.slug}`}>
        {profileTable}
      </div>
      <div className="unit-profile__details">
        <RichText json={entry.fields.bodyBefore} deepLinks noWrapper />
        {cost}
        {!is4th && unitCategoryDisplay}
        {troopTypeDisplay}
        {baseSize}
        {unitSize}
        {armourValue}
        {equipment}
        {magicRules}
        {magicItems}
        {options}
        {specialRules}
        {optionalRules}
        {armyLists}
        {relatedUnits}
        <RichText json={entry.fields.bodyAfter} deepLinks noWrapper />
      </div>
    </div>
  )
}

export default UnitProfile