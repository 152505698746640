import { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'components/link'

import IndexList from 'components/index-list'

import { Flex } from '@contentful/f36-components'
import { ArrowUpIcon, ArrowDownIcon } from '@contentful/f36-icons'

const MainRulebookNavSection = ({ section }) => {

  // Set up hide/show vars
  const [showLinks, setShowLinks] = useState(false)
  const icon = showLinks ? 'ArrowUp' : 'ArrowDown'

  // Set up router
  const router = useRouter()

  // Set icon button
  let iconButton = showLinks ?
    <ArrowUpIcon
      className="nav-icon link-icon"
      variant="primary"
      size="large"
      onClick={() => setShowLinks(!showLinks)}
    />
  :
    <ArrowDownIcon
      className="nav-icon link-icon"
      variant="primary"
      size="large"
      onClick={() => setShowLinks(!showLinks)}
    />

  // Change icon/label for faq
  if (process.env.NEXT_PUBLIC_RESERVED_RULETYPES.includes(section.slug) || !section.rules.length) {
    iconButton = (
      <ArrowUpIcon
        className="nav-icon nav-icon--direct link-icon"
        variant="primary"
        size="large"
        onClick={() => router.push(`/${section.slug}`)}
      />
    )
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="nav-header"
        fullWidth
      >
        <div className="nav-header__section">
          <Link href={`/${section.slug}`}>
            {section.name}
          </Link>
        </div>
        {iconButton}
      </Flex>
      {showLinks && <IndexList entries={section.rules} organize={false} />}
    </>
  )
}

export default MainRulebookNavSection