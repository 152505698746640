import RichText from './rich-text'

import { organizeMagicItems, pluralizeMagicItems } from 'library/helpers/content'
import { removeParens, slugify } from 'library/helpers/utilities'

import styles from './army-list.module.scss'

const MagicItemList = ({ magicItems = [], className = '', hideSections = false, hideCommon = false }) => {

  // Items required
  if (magicItems.length < 1) return null

  // Check if more than one type is present
  if (!hideSections) {
    const testType = magicItems[0].fields.type
    hideSections = true
    for (const item of magicItems) {
      if (testType !== item.fields.type) {
        hideSections = false
        break
      }
    }
  }

  // For unit entries, hide magic items with associated types (common)
  if (hideCommon) magicItems = magicItems.filter(item => !item.fields.magicItemType)

  let tempType
  return (
    <>
      {organizeMagicItems(magicItems).map(entry => {

        const type = entry.fields.type && entry.fields.type !== 'Unique' ? `${entry.fields.type}` : ''

        let cost = ''
        if (entry.fields.cost && entry.fields.cost > 0) cost = `${entry.fields.cost} pts`
        if (entry.fields.costOverride) cost = entry.fields.costOverride.replaceAll('point', 'pt')

        const header = `${removeParens(entry.fields.name)} (${type}${type && cost ? ', ' : ''}${cost})`

        let section
        if (type && tempType !== type) {
          tempType = type
          section = pluralizeMagicItems(type)
        }

        return (
          <div key={entry.sys.id} className={styles.magicItem}>
            {section && !hideSections ? <h3 id={slugify(section)}>{section}</h3> : null}
            <RichText
              json={entry.fields.body}
              header={header}
              className={styles.expandedList}
            />
          </div>
        )
      })}
    </>
  )
}

export default MagicItemList