import LazyLoadLink from 'components/lazy-load-link'
import DetailedLink from 'components/detailed-link'

import { removeParens } from 'library/helpers/utilities'

/**
 * Organize rich text special rules into lists
 * @param  {obj}  data
 * @param  {bool} singleLine
 * @return {obj}
 */
export const organizeSpecialRules = (data, separator = '', nodeType = '', equipment = false) => {

  // Extra the rich text json to manipulate
  const filteredContent = { ...data }

  // Process rich text into organized list
  if (filteredContent.content) {
    for (const row of filteredContent.content) {

      // Disable node type
      row.nodeType = nodeType

      // Only target text inline links
      row.content = row.content.filter(item => {
        return item.nodeType === 'entry-hyperlink' && item.content[0].value;
      })

      // Sort alphabetically
      row.content.sort(function(a, b) {
        let nameA = a.content[0].value.toLowerCase()
        let nameB = b.content[0].value.toLowerCase()

        // Remove 'the ', ", ' from alphabetizing
        if (nameA.startsWith('the ')) nameA = nameA.substring(4)
        if (nameB.startsWith('the ')) nameB = nameB.substring(4)
        if (nameA.startsWith('"')) nameA = nameA.substring(1)
        if (nameB.startsWith('"')) nameB = nameB.substring(1)
        if (nameA.startsWith("'")) nameA = nameA.substring(1)
        if (nameB.startsWith("'")) nameB = nameB.substring(1)

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })

      // Use the default rule name
      if (equipment) {
        if (row.content.length === 0) return false
        row.content.map(item => {
          item.content[0].value = removeParens(item.data.target.fields.name)
          return item
        })
      }

      // Build as single line, comma separated
      if (separator) {

        // Comma separate entry linked nodes
        const separatedContent = []
        for (let i = 0, len = row.content.length; i < len; i++) {
          separatedContent.push(row.content[i])
          if (i + 1 < len) {
            separatedContent.push(
              {
                data: {},
                marks: [],
                nodeType: "text",
                value: separator
              }
            )
          }
        }
        row.content = separatedContent

      }
    }
  }

  return filteredContent

}

/**
 * Generate a next link using entry data
 * @param  {obj} entry
 * @param  {str} linkText
 * @param  {bool} deepLinks
 * @param  {str} classes
 * @return {obj}
 */
export const generateEntryLink = (entry, linkText = '', deepLinks = false, classes = '') => {

  // No data of any type
  if (!entry) return linkText

  // Use default entry name if link not provided
  if (!linkText && entry.fields?.name) linkText = entry.fields.name

  // If entry is indexed link data, return early
  if (entry.id && entry.href) {
    return (
      <DetailedLink
        text={linkText}
        href={entry.href}
        entryId={entry.id}
        deep={deepLinks}
        classes={classes}
      />
    )
  }

  // Build links base on content type
  let hrefParent = ''
  const hrefChild = entry.fields.slug ? `/${entry.fields.slug}` : ''
  switch (entry.sys.contentType.sys.id) {
    case 'spell':
      hrefParent = '/spell'
      break
    case 'magicItem':
      hrefParent = '/magic-item'
      break
    case 'association':
      hrefParent = '/army'
      break
    case 'unit':
      hrefParent = '/unit'
      break
    case 'armyListEntry':
      hrefParent = '/unit'
      break
    case 'armyList':
      hrefParent = '/army-list'
      break
    case 'rule':

      // If include isn't deep enough, rerun as lazy link
      if (entry.fields.ruleType && entry.fields.ruleType[0]) {
        if (entry.fields.ruleType[0].fields) {
          hrefParent = `/${entry.fields.ruleType[0].fields.slug}`
        } else {
          return <LazyLoadLink id={entry.sys.id} linkText={linkText} deep={deepLinks} classes={classes} />
        }
      } else {
        return <LazyLoadLink id={entry.sys.id} linkText={linkText} deep={deepLinks} classes={classes} />
      }

      break
    case 'post':
      hrefParent = '/latest'
      break
    case 'magicLore':
      hrefParent = '/cards'
      break
    default:
      break
  }

  // Return build link
  return (
    <DetailedLink
      text={linkText}
      href={`${hrefParent}${hrefChild}`}
      entryId={entry.sys.id}
      deep={deepLinks}
      classes={classes}
    />
  )
}

import Chart from 'components/chart'
import WeaponProfile from 'components/weapon-profile'
import ArmourProfile from 'components/armour-profile'
import SpellList from 'components/spell-list'
import MagicItemList from 'components/magic-item-list'
import UnitProfile from 'components/unit-profile'
import RichText from 'components/rich-text'
import RichTextArmyList from 'components/army-list/rich-text'

/**
 * Generate a block display
 * @param  {obj} entry
 * @return {obj}
 */
export const generateEntryBlock = (entry, armyList = false) => {
  switch (entry.sys.contentType.sys.id) {
    case 'chart':
      return <Chart config={entry.fields.chart} entry={entry} />
      break
    case 'weaponProfile':
      return <WeaponProfile entry={entry} />
      break
    case 'armourProfile':
      return <ArmourProfile entry={entry} />
      break
    case 'magicLore':
      return <SpellList entry={entry} />
      break
    case 'magicItemType':
      return <MagicItemList entry={entry} />
      break
    case 'unitProfile':
      return <UnitProfile entry={entry} />
      break
    case 'rule':
      if (armyList) return <RichTextArmyList key={entry.sys.id} json={entry.fields.body} header={removeParens(entry.fields.name)} />
      return <RichText key={entry.sys.id} json={entry.fields.body} header={removeParens(entry.fields.name)} deepLinks />
      break
    default:
      return null
      break
  }
}

/**
 * Pass through for node scripts to import module
 * @param  {arr}  entries
 * @return {obj}
 */
const node = require('/library/helpers/node')
export const organizeAssociations = (entries = []) => {
  return node.organizeAssociations(entries)
}

/**
 * Organize magic items
 * @param  {arr}  entries
 * @return {[arr]}
 */
export const organizeMagicItems = (entries = []) => {

  // Set groups
  const groupedItems = []

  // Sort alphabetically first
  const sortedItems = [ ...entries ]
  sortedItems.sort(function(a, b) {
    let nameA = a.fields.name.toLowerCase()
    let nameB = b.fields.name.toLowerCase()

    // Remove 'the ', ", ' from alphabetizing
    if (nameA.startsWith('the ')) nameA = nameA.substring(4)
    if (nameB.startsWith('the ')) nameB = nameB.substring(4)
    if (nameA.startsWith('"')) nameA = nameA.substring(1)
    if (nameB.startsWith('"')) nameB = nameB.substring(1)
    if (nameA.startsWith("'")) nameA = nameA.substring(1)
    if (nameB.startsWith("'")) nameB = nameB.substring(1)

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })

  // Sort by points cost
  sortedItems.sort(function(a, b) {
    const itemA = a.fields.cost
    const itemB = b.fields.cost
    if (itemA < itemB) return 1
    if (itemA > itemB) return -1
    return 0
  })

  // Group by type
  let typeOrder = [
    'Magic Weapon',
    'Magic Armour',
    'Talisman',
    'Ward',
    'Arcane Item',
    'Enchanted Item',
    'Wizard Arcana',
    'Magic Standard',
    'Bound Spell',
    'Ability',
    'Unique'
  ]
  if (process.env.NEXT_PUBLIC_MAGICITEM_ORDER) typeOrder = process.env.NEXT_PUBLIC_MAGICITEM_ORDER.split(',')
  for (const type of typeOrder) {
    for (const item of sortedItems) {
      if (item.fields.type === type) groupedItems.push(item)
    }
  }

  return groupedItems
}

/**
 * Organize spells
 * @param  {arr}  entries
 * @return {[arr]}
 */
export const organizeSpells = (entries = []) => {

  // Set groups
  let groupedItems = []

  // Sort by points cost
  const sortedItems = [ ...entries ]
  sortedItems.sort(function(a, b) {
    const itemA = a.fields.cost
    const itemB = b.fields.cost
    if (itemA < itemB) return 1
    if (itemA > itemB) return -1
    return 0
  })

  // Group by type
  const typeOrder = [
    'Lore Attribute',
    'Signature Spell',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    'Bound Spell',
    'Innate Bound Spell',
    'Power Level'
  ]
  for (const type of typeOrder) {
    for (const item of sortedItems) {
      if (item.fields.type === type) groupedItems.push(item)
    }
  }

  // If no groupedItems, default to sortedItems
  if (groupedItems.length === 0) groupedItems = sortedItems

  return groupedItems
}

/**
 * Return plural form of magic item
 * @param  {str} type
 * @return {str}
 */
export const pluralizeMagicItems = type => {
  if (type === 'Magic Armour') return type
  if (type === 'Wizard Arcana') return type
  if (type === 'Ability') return 'Abilities'
  return `${type}s`
}