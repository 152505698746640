import { Icon } from '@contentful/f36-icon'

const IconBug = ({ entry }) => {
  return (
    <Icon className="icon--bug">
      <>
        <path d="M24,13.4c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3h-3.4c0,1.7-0.3,3.2-1,4.3l3.1,3.1c0.2,0.2,0.3,0.4,0.3,0.7
          S22,23,21.8,23.2c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-3-3c0,0-0.1,0.1-0.2,0.2s-0.3,0.2-0.6,0.4s-0.6,0.4-1,0.5
          c-0.3,0.2-0.7,0.3-1.2,0.4s-1,0.2-1.5,0.2V8.6H11v13.4c-0.5,0-1-0.1-1.5-0.2s-0.9-0.3-1.3-0.5c-0.4-0.2-0.7-0.4-1-0.6
          c-0.3-0.2-0.5-0.4-0.7-0.5l-0.2-0.2l-2.7,3.1c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.7
          c0-0.3,0.1-0.5,0.2-0.7l3-3.4c-0.6-1.1-0.9-2.5-0.9-4.1H1c-0.3,0-0.5-0.1-0.7-0.3C0.1,13.9,0,13.7,0,13.4c0-0.3,0.1-0.5,0.3-0.7
          s0.4-0.3,0.7-0.3h3.4V8.1L1.7,5.5C1.5,5.3,1.4,5.1,1.4,4.8s0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l2.6,2.6
          h12.7l2.6-2.6c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-2.6,2.6v4.4H23
          c0.3,0,0.5,0.1,0.7,0.3C23.9,13,24,13.2,24,13.4L24,13.4z M16.8,4.8H7.2c0-1.3,0.5-2.5,1.4-3.4C9.5,0.5,10.7,0,12,0s2.5,0.5,3.4,1.4
          C16.3,2.3,16.8,3.5,16.8,4.8L16.8,4.8z" />
      </>
    </Icon>
  )
}

export default IconBug