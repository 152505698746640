import { getEntryAsync } from 'library/helpers/api'
import useSWRImmutable from 'swr/immutable'

import { generateEntryBlock } from 'library/helpers/content'

import { Spinner } from '@contentful/f36-components'

const LazyLoadBlock = ({ id }) => {

  // Required
  if (!id) return null

  // Cache response with SWR
  const fetcher = () => getEntryAsync(id).then(res => res)
  const { data, error } = useSWRImmutable(`/api/entry/${id}`, fetcher)

  return (
    <>
      {!data && !error ? <Spinner /> : null}
      {data && data.fields ? generateEntryBlock(data) : null}
    </>
  )
}

export default LazyLoadBlock