import { removeParens } from 'library/helpers/utilities'

import styles from './army-list.module.scss'

const UnitTable = ({ rows = [] }) => {

  let displayRows
  if (rows && rows.length > 0) {
    displayRows = rows.map((row, index) => {
      return (
        <tr key={index}>
          <td>{row.Name}</td>
          <td>{row.M}</td>
          <td>{row.WS}</td>
          <td>{row.BS}</td>
          <td>{row.S}</td>
          <td>{row.T}</td>
          <td>{row.W}</td>
          <td>{row.I}</td>
          <td>{row.A}</td>
          <td>{row.Ld}</td>
        </tr>
      )
    })
  } else {
    return null
  }

  return (
    <table className={`${styles.unitTable} ${styles.genericTable}`}>
      <thead>
        <tr>
            <th></th>
            <th>M</th>
            <th>WS</th>
            <th>BS</th>
            <th>S</th>
            <th>T</th>
            <th>W</th>
            <th>I</th>
            <th>A</th>
            <th>Ld</th>
        </tr>
      </thead>
      <tbody>
        {displayRows}        
      </tbody>
    </table>
  )
}

export default UnitTable


