import RichText from 'components/rich-text'

import { Table } from '@contentful/f36-components'

const Chart = ({ config, entry }) => {

  // If richText is set, use that in first
  if (entry && entry.fields.richText) return <RichText json={entry.fields.richText} deepLinks noWrapper />

  // Must have rows
  if (!config || !config.rows) return null

  // Set to mutable var
  const rows = [ ...config.rows ]

  // Extract header row (if applicable)
  const headerRow = config.hasHeaderRow ? rows.shift() : []

  // Build table header
  let tableHeader
  if (headerRow.length > 0) {
    tableHeader = (
      <Table.Head>
        <Table.Row>
          {headerRow.map((row, index) => <Table.Cell key={index} className={row.length < 8 ? 'nowrap' : ''}>{row}</Table.Cell>)}
        </Table.Row>
      </Table.Head>
    )
  }

  // Build table body
  let tableBody = []
  if (rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      tableBody.push(
        <Table.Row key={i}>
        {rows[i].map((row, index) => {
          return (
            <Table.Cell key={index} className={row.length < 8 ? 'nowrap' : ''}>{row}</Table.Cell>
          )
        })}
        </Table.Row>
      )
    }
  }

  return (
    <div className={`table-wrapper ${entry.fields.slug} ${config.class ? config.class : ''}`}>
      <Table className={`generic-table ${config.hasHeaderColumn ? 'header-column' : ''}`}>
        {tableHeader}
        {tableBody.length > 0 && <Table.Body>{tableBody}</Table.Body>}
      </Table>
    </div>
  )
}

export default Chart