import UnitTable from './unit-table'
import RichText from './rich-text'

import { Table } from '@contentful/f36-components'

import styles from './army-list.module.scss'

const DisplayTable = ({ config, entry }) => {

  // If richText is set, use that verion first
  if (entry && entry.fields.richText) return <RichText json={entry.fields.richText} noWrapper />

  // Must have rows
  if (!config || !config.rows) return null

  // Set to mutable var
  const rows = [ ...config.rows ]

  // Look out for profile charts
  if (config.class && config.class.includes('unit-profile')) {
    const unitRows = []
    for (const row of rows.splice(1)) {
      let tempObj = {}
      tempObj.Name = row[0]
      tempObj.M = row[1]
      tempObj.WS = row[2]
      tempObj.BS = row[3]
      tempObj.S = row[4]
      tempObj.T = row[5]
      tempObj.W = row[6]
      tempObj.I = row[7]
      tempObj.A = row[8]
      tempObj.Ld = row[9]
      unitRows.push(tempObj)
    }
    return <UnitTable rows={unitRows} />
  }

  // Extract header row (if applicable)
  const headerRow = config.hasHeaderRow ? rows.shift() : []

  // Build table header
  let tableHeader
  if (headerRow.length > 0) {
    tableHeader = (
      <thead>
        <tr>
          {headerRow.map((row, index) => <th key={index} className={row.length < 8 ? styles.nowrap : ''}>{row}</th>)}
        </tr>
      </thead>
    )
  }

  // Build table body
  let tableBody = []
  if (rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      tableBody.push(
        <tr key={i}>
        {rows[i].map((row, index) => {
          return (
            <td key={index} className={row.length < 8 ? styles.nowrap : ''}>{row}</td>
          )
        })}
        </tr>
      )
    }
  }

  return (
    <div className={`table-wrapper`}>
      <table className={`${styles.chartTable} ${styles.genericTable}`}>
        {tableHeader}
        {tableBody.length > 0 && <Table.Body>{tableBody}</Table.Body>}
      </table>
    </div>
  )
}

export default DisplayTable