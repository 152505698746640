import { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'components/link'

import IndexList from 'components/index-list'

import { Flex } from '@contentful/f36-components'
import { ArrowUpIcon, ArrowDownIcon } from '@contentful/f36-icons'

const ArmyRulesNavSection = ({ section }) => {

  // Set up hide/show vars
  const [showLinks, setShowLinks] = useState(false)

  // Set up router
  const router = useRouter()

  // Set icon button
  let iconButton = showLinks ?
    <ArrowUpIcon
      className="nav-icon link-icon"
      variant="primary"
      size="large"
      onClick={() => setShowLinks(!showLinks)}
    />
  :
    <ArrowDownIcon
      className="nav-icon link-icon"
      variant="primary"
      size="large"
      onClick={() => setShowLinks(!showLinks)}
    />

  // Change icon/label for in-process army books
  if (process.env.NEXT_PUBLIC_ARMIES_UPDATING || !section.rules.length) {
    iconButton = (
      <ArrowUpIcon
        className="nav-icon nav-icon--direct link-icon"
        variant="primary"
        size="large"
        onClick={() => router.push(`${section.href}`)}
      />
    )
  }

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="nav-header"
        fullWidth
      >
        <div className="nav-header__section">
          <Link
            href={`${section.href}`}
          >
            {section.name}
          </Link>
        </div>
        {iconButton}
      </Flex>
      {showLinks && <IndexList entries={section.rules} />}
    </>
  )
}

export default ArmyRulesNavSection