import { useState, useEffect } from 'react'
import Link from 'components/link'
import { getEntryAsync } from 'library/helpers/api'
import useSWRImmutable from 'swr/immutable'

import RichText from 'components/rich-text'
import UnitProfile from 'components/unit-profile'
import RelatedLinks from 'components/related-links'

import { Spinner, Note } from '@contentful/f36-components'

const DetailedModal = ({ id, updateTitle, href }) => {

  // Required
  if (!id) return null

  // Cache response with SWR
  const fetcher = () => getEntryAsync(id).then(res => res)
  const { data, error } = useSWRImmutable(`/api/entry/${id}`, fetcher)

  // Update parent modal as needed
  useEffect(() => {
    if (error) updateTitle('Error')
    if (data) {
      if (data.fields) {
        updateTitle(data.fields.name)
      } else {
        updateTitle('Preview Error')
      }
    }
  }, [data, error])

  // Content to display
  let content
  if (data && data.fields) {
    content = <RichText json={data.fields.body} />
    // TEMP: Include units and armyListEntry
    if (data.sys.contentType.sys.id === 'unit' || data.sys.contentType.sys.id === 'armyListEntry') content = <UnitProfile entry={data} />
  }

  return (
    <div className="entry-preview">
      {!data && !error ? <Spinner size="large" /> : null}
      {error && !data ? <Note variant="warning">Unable to load entry.</Note> : null}
      {content}
      <RelatedLinks entry={data} />
      {data && !data.fields ?
        <Link href={href}>
          Click here to go directly to page...
        </Link>
        : null}
    </div>
  )
}

export default DetailedModal