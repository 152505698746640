import Link from 'components/link'

import RichText from 'components/rich-text'

const MagicItem = ({ entry }) => {

  // Built points value
  let cost
  if (entry.fields.cost && entry.fields.cost > 0) cost = `${entry.fields.cost} points`
  if (entry.fields.costOverride) cost = entry.fields.costOverride

  return (
    <div className="magic-item">
      <div className="magic-item__header">
        <span className="magic-item__name">
          <Link href={`/magic-item/${entry.fields.slug}`}>
            {entry.fields.name && entry.fields.name}
          </Link>
          <span className="magic-item__type">({entry.fields.type && entry.fields.type})</span>
        </span>
        <span className="magic-item__cost">{cost}</span>
      </div>

      <RichText json={entry.fields.description} className="section-intro" />
      <RichText json={entry.fields.body} deepLinks />
    </div>
  )
}

export default MagicItem