import { sdk } from 'library/configs/contentful'
import { fetchData, cleanCircularReferences } from 'library/helpers/utilities'

// Get base URL for queries
let baseUrl = process.env.NODE_ENV === 'production' ? process.env.NEXT_PUBLIC_PROD_URL : process.env.NEXT_PUBLIC_DEV_URL

// If alt URL is set, 
if (process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_ALT_PROD_URL) {
  if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ALT_PROD_URL.includes(window.location.hostname)) {
    baseUrl = process.env.NEXT_PUBLIC_ALT_PROD_URL    
  }
}

/**
 * Catch-all wrapper for SDK methods to allow async data retrieval for multiple entries
 * @param  {obj} params
 * @return {obj|arr}
 */
export const getEntriesAsync = async params => {

  // Convert object to a query string
  const queryString = Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')

  // Build API endpoint and return promise
  return await fetchData(`${baseUrl}/api/entries?${queryString}`)

}

/**
 * Catch-all wrapper for SDK methods to allow async data retrieval for single entry
 * @param  {obj} params
 * @return {obj|arr}
 */
export const getEntryAsync = async id => {

  // Build API endpoint and return promise
  return await fetchData(`${baseUrl}/api/entry/${id}`)

}

/**
 * DEPRECATED: Get indexed link data
 * @param  {str} id
 * @return {obj}
 */
export const getLinkData = async id => {

  // Build API endpoint and return promise
  return await fetchData(`${baseUrl}/api/link/${id}`)

}

/**
 * Get additional data by field and depth
 * @param  {obj} entry
 * @param  {str} contentType
 * @param  {str} field
 * @param  {int} include
 * @return {obj}
 */
export const getIncludedFieldData = async (entry, contentType = 'rule', field = 'body', include = 2) => {

  // If target field is missing, skip
  if (!entry?.fields[field]) return entry

  // Run query & append new data
  await sdk.getEntries({
    content_type: contentType,
    limit: 1,
    include: include,
    'sys.id': entry.sys.id,
    select: `fields.${field}`
  })
  .then((res) => {
    const expandedEntry = res.items[0] ? cleanCircularReferences(res.items[0]) : {}
    entry.fields[field] = expandedEntry.fields[field] ? expandedEntry.fields[field] : entry.fields[field]
  })
  .catch(console.error)

  // Return updated entry
  return entry
}

/**
 * Async/await loop of entries to get additional data by depth
 * @param  {arr} items
 * @param  {str} contentType
 * @param  {str} field
 * @param  {int} include
 * @return {arr}
 */
export const iterateIncludedFieldData = async (items, contentType, field, include) => {
  let newArray = []
  for await (let item of items) {
    newArray.push(await getIncludedFieldData(item, contentType, field, include))
  }
  return newArray
}