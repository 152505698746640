import { useState, useEffect, useContext } from 'react'
import { AppContext } from 'library/context'

import { Switch } from '@contentful/f36-components'

const Preferences = ({ entry }) => {

  // Get context data
  const { prefClasses, setPrefClasses } = useContext(AppContext)

  // Set states
  const [displayLoreDescriptions, setDisplayLoreDescriptions] = useState(true)
  const [displayPopUpRules, setDisplayPopUpRules] = useState(true)
  const [displayUnitOptions, setDisplayUnitOptions] = useState(false)
  const [expandCrossRefs, setExpandCrossRefs] = useState(false)
  const [hideNotifications, setHideNotifications] = useState(false)
  const [hideActionButtons, setHideActionButtons] = useState(false)
  const [enableDarkMode, setEnableDarkMode] = useState(false)

  // Set prefs based on current context
  useEffect(() => {
    if (prefClasses.includes('hide-lore')) setDisplayLoreDescriptions(false)
    if (prefClasses.includes('hide-pop')) setDisplayPopUpRules(false)
    if (prefClasses.includes('show-options')) setDisplayUnitOptions(true)
    if (prefClasses.includes('expand-cross')) setExpandCrossRefs(true)
    if (prefClasses.includes('hide-notice')) setHideNotifications(true)
    if (prefClasses.includes('hide-actions')) setHideActionButtons(true)
    if (prefClasses.includes('dark-mode')) setEnableDarkMode(true)
  }, [])

  // Update context
  const handlePrefUpdate = (addClass, className) => {
    if (addClass) {
      const uniqueArray = [...new Set(prefClasses)]
      setPrefClasses([ ...uniqueArray, className])
    } else {
      const uniqueArray = [...new Set(prefClasses)]
      const index = uniqueArray.indexOf(className)
      uniqueArray.splice(index, 1)
      setPrefClasses(uniqueArray, false)
    }
  }

  return (
    <div className="preferences">
      <Switch
        isChecked={displayLoreDescriptions}
        id="lore"
        onChange={() => {
          setDisplayLoreDescriptions(!displayLoreDescriptions)
          handlePrefUpdate(displayLoreDescriptions, 'hide-lore')
        }}
      >
        Display lore descriptions
      </Switch>
      <Switch
        isChecked={displayPopUpRules}
        id="pop"
        onChange={() => {
          setDisplayPopUpRules(!displayPopUpRules)
          handlePrefUpdate(displayPopUpRules, 'hide-pop')
        }}
      >
        Display pop-up rules
      </Switch>
      <Switch
        isChecked={displayUnitOptions}
        id="options"
        onChange={() => {
          setDisplayUnitOptions(!displayUnitOptions)
          handlePrefUpdate(!displayUnitOptions, 'show-options')
        }}
      >
        Display unit details
      </Switch>
      <Switch
        isChecked={expandCrossRefs}
        id="cross"
        onChange={() => {
          setExpandCrossRefs(!expandCrossRefs)
          handlePrefUpdate(!expandCrossRefs, 'expand-cross')
        }}
      >
        Expand cross-references
      </Switch>
      <Switch
        isChecked={hideNotifications}
        id="notice"
        onChange={() => {
          setHideNotifications(!hideNotifications)
          handlePrefUpdate(!hideNotifications, 'hide-notice')
        }}
      >
        Hide notifications
      </Switch>
      <Switch
        isChecked={hideActionButtons}
        id="actions"
        onChange={() => {
          setHideActionButtons(!hideActionButtons)
          handlePrefUpdate(!hideActionButtons, 'hide-actions')
        }}
      >
        Hide page actions
      </Switch>
      <Switch
        isChecked={enableDarkMode}
        id="dark"
        onChange={() => {
          setEnableDarkMode(!enableDarkMode)
          handlePrefUpdate(!enableDarkMode, 'dark-mode')
        }}
      >
        Enable dark mode
      </Switch>
    </div>
  )
}

export default Preferences