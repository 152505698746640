import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { fetchData } from 'library/helpers/utilities'
import { trackEvent } from 'library/helpers/ga'

import {
  Spinner,
  TextInput,
  Textarea,
  Button,
  Note,
  FormLabel
} from '@contentful/f36-components'

const ReportBugForm = ({ inputRef }) => {

  // Get routing object for pathing
  const router = useRouter()

  // Set state
  const [page, setPage] = useState('')
  const [desc, setDesc] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  // Input ref
  if (!inputRef) inputRef = useRef(null)

  // Set url for bug reporter
  useEffect(() => {
    const currentUrl = window.location.href
    setPage(currentUrl)
  }, [router])

  // On submit
  const handleSubmit = () => {

    // Build params
    const params = new URLSearchParams({
      page: `${encodeURIComponent(page)}`,
      desc: `(${process.env.NEXT_PUBLIC_TAG.toUpperCase()}) - ${encodeURIComponent(desc)}`,
    });
    const response = fetchData(`/api/sendBugReport?${params.toString()}`)

    // Handle response
    setLoading(true)
    setError(false)
    setSuccess(false)
    response.then(res => {
      if (res === true) {
        setSuccess(true)
        setPage('')
        setDesc('')
        trackBugReport()
      } else {
        setError(true)
      }
    }).catch(err => {
      setError(true)
    }).then(() => {
      setLoading(false)
    })
  }

  // Track when bug reported
  const trackBugReport = () => {
    trackEvent({ action: "reportBugSubmit" })
  }

  // Page input
  const inputPage = (
    <div className="form__input">
      <FormLabel>URL/Page:</FormLabel>
      <TextInput
        value={page}
        onChange={() => setPage(event.target.value)}
      />
    </div>
  )

  // Description textarea
  const inputDesc = (
    <div className="form__input">
      <FormLabel>Describe the Issue:</FormLabel>      
      <Textarea
        value={desc}
        ref={inputRef}
        onChange={() => setDesc(event.target.value)}
        rows="6"
        placeholder="Please be as descriptive as possible..."
      />
    </div>
  )

  // Generic error message
  const errorNote = (
    <div className="form__input">
      <Note variant="negative">
        There was an error. Please try again.
      </Note>
    </div>
  )

  // Generic success message
  const successNote = (
    <div className="form__input">
      <Note variant="positive">
        Your bug report was received. Thank you.
      </Note>
    </div>
  )

  // Submit button
  const submitButton = (
    <div className="form__input">
      <Button
        onClick={handleSubmit}
        className="form__input"
        variant="primary"
        style={{ marginRight: '1rem' }}
        isDisabled={page && desc && !loading ? false : true}                
      >
        Send
      </Button>
      {loading && <Spinner size="large" />}
    </div>
  )

  return (
    <div className="form__wrapper form__wrapper--report-bug">
      {error && errorNote}
      {success && successNote}
      {!success && inputPage}
      {!success && inputDesc}
      {!success && submitButton}
    </div>
  )
}

export default ReportBugForm