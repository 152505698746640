import Link from 'components/link'

import RichText from 'components/rich-text'

const Spell = ({ entry, page = false }) => {

  // Set defaults
  let name = entry.fields.name
  let type = entry.fields.type ? entry.fields.type : ''
  let range = entry.fields.range ? entry.fields.range : ''

  // Set casting/power level
  let castingValuePrefix = 'Casting Value:'
  let castingValueSuffix = '+'
  let castingValue = entry.fields.castingValue ? entry.fields.castingValue : ''
  let castingValueOverride = entry.fields.castingValueOverride ? entry.fields.castingValueOverride : ''
  if (castingValueOverride) {
    castingValue = castingValueOverride
    castingValueSuffix = ''
  }

  // Set display vars
  let displayName = name
  let displayDetails = []

  // Update vars based on situation
  if (type) {
    switch (true) {

      // Type is number (string)
      case !/\s/.test(type):
        displayName = !page ? `${type}. ${name}` : `Spell Level ${type}`
        if (process.env.NEXT_PUBLIC_TAG === 'wap23') displayName = !page ? `${name} (Level ${type})` : `Level ${type}`
        break

      // Power Level
      case type === 'Power Level':
        displayName = !page ? displayName : ''
        castingValuePrefix = 'Power:'
        castingValueSuffix = ''
        break

      default:
        displayName = !page ? `${name} (${type})` : type
        break
    }
  }

  // Put it all together
  if (castingValue) displayDetails.push(`${castingValuePrefix} ${castingValue}${castingValueSuffix}`)
  if (range) displayDetails.push(`Range: ${range}`)

  // Remove details for TOW
  if (process.env.NEXT_PUBLIC_TAG === 'tow') displayDetails = []

  return (
    <div className="spell">
      <div className="spell__header">
        {!page && displayName && (
          <span className="spell__name">
            <Link href={`/spell/${entry.fields.slug}`}>
              {displayName}
            </Link>
          </span>
        )}
        {page && type && displayName ? <span className="spell__name">{displayName}</span> : null}
        {displayDetails.length !== 0 && <span className="spell__details">{displayDetails.map((entry, index) => <span key={index}>{entry}</span>)}</span>}
      </div>
      <RichText json={entry.fields.description} className="section-intro" />
      <RichText json={entry.fields.body} deepLinks />
    </div>
  )
}

export default Spell