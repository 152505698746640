import { useState, useEffect } from 'react'
import Link from 'components/link'
import { useRouter } from 'next/router'

import RichText from 'components/rich-text'
import DetailedModal from 'components/detailed-modal'

import { Modal } from '@contentful/f36-components'
import { CopyIcon } from '@contentful/f36-icons'

const DetailedLink = ({ text, href, entryId, deep = false, classes = '' }) => {

  // Set state for header items
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState('Loading Preview...')

  // Get routing object for pathing
  const router = useRouter()

  // Clear/hide preview on route change
  useEffect(() => {
    if (deep) {
      router.events.on('routeChangeComplete', () => setShowModal(false))
    }
    return () => {
      router.events.off('routeChangeComplete')
    }
  }, [])

  // Build preview modal if running deep links
  let preview
  if (deep) {
    preview = (
      <>
        <CopyIcon
          className="deep-link__icon link-icon"
          onClick={() => setShowModal(true)}
        />
        <Modal
          isShown={showModal}
          onClose={() => setShowModal(false)}
          title={title}
          position="center"
          size="large"
          overlayProps={{ "className": "modal-overlay" }}
        >
          {showModal && <DetailedModal id={entryId} updateTitle={setTitle} href={href} />}
        </Modal>
      </>
    )
  }

  return (
    <span className={`${deep ? 'detailed-link' : ''} ${classes}`}>
      <Link href={href} prefetch={false}>
        {text}
      </Link>
      {deep && preview}
   </span>
  )
}

export default DetailedLink