import { useRouter } from 'next/router'

import MagicItem from 'components/magic-item'

import { organizeMagicItems, pluralizeMagicItems } from 'library/helpers/content'
import { slugify } from 'library/helpers/utilities'

const MagicItemList = ({ entry, preload = [], hideSections = false }) => {

  // Get routing object for pathing
  const router = useRouter()

  // For display
  let displayItems = []

  if (preload.length > 0) {

    // Get items
    displayItems = organizeMagicItems(preload)
    let tempType

    // Check if more than one type is present
    if (!hideSections) {
      const testType = displayItems[0].fields.type
      hideSections = true
      for (const item of displayItems) {
        if (testType !== item.fields.type) {
          hideSections = false
          break
        }
      }
    }

    return (
      <div className="magic-item-list">
        {displayItems.map(entry => {

          // Divide magic items sections
          const type = entry.fields.type && entry.fields.type !== 'Unique' ? `${entry.fields.type}` : ''
          let section
          if (type && tempType !== type) {
            tempType = type
            section = pluralizeMagicItems(type)
          }

          return (
            <div key={entry.sys.id} className="magic-item">
              {section && !hideSections ? <h2 className="anchor" id={slugify(section)} onClick={() => { router.push(`#${slugify(section)}`) }}>{section}</h2> : null}
              <MagicItem entry={entry} />
            </div>
          )
        })}
      </div>
    )

  } else {
    return null
  }
}

export default MagicItemList