import { useContext } from 'react'
import { AppContext } from 'library/context'

import { generateEntryLink } from 'library/helpers/content'
import DetailedLink from 'components/detailed-link'

const LazyLoadLink = ({ id, linkText = '', deep = false, classes = '' }) => {

  // Required
  if (!id) return linkText

  // Get link index
  const { linkIndex } = useContext(AppContext)

  // Build link
  if (linkIndex[id]) {
    linkText = generateEntryLink({ id: id, href: linkIndex[id] }, linkText, deep, classes)
  } else {
    linkText = <span className={`no-link ${classes}`}>{linkText}</span>
  }


  return linkText
}

export default LazyLoadLink