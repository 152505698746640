import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'library/hooks/useWindowSize'
import { trackEvent } from 'library/helpers/ga'

import { IconButton, Modal, Tooltip } from '@contentful/f36-components'
import { LinkIcon } from '@contentful/f36-icons'

import IconBug from 'components/icon-bug'
import ReportBugForm from 'components/report-bug-form'

const PageActions = ({ entry, includeQuery = false }) => {

  // Set display state
  const [showBugReportModal, setShowBugReportModal] = useState(false)
  const [showText, setShowText] = useState(false)

  // Use hooks to watch for window resize/scroll
  const [windowWidth] = useWindowSize()

  // Show copy message
  useEffect(() => {
    let timeout
    if (showText) {
      timeout = setTimeout(() => {
        setShowText(false)
      }, 1500)
    }
    return () => clearTimeout(timeout);
  }, [showText])

  // Show modal and report
  const handleBugReport = () => {
    trackEvent({ action: "reportBugOpen" })
    setShowBugReportModal(true)
  }

  // Copy to clipboard and show UI
  const handleCopyUrl = () => {
    trackEvent({ action: "copyLink" })
    setShowText(true)
    let currentUrl = window.location.href
    if (!includeQuery) currentUrl = currentUrl.split('?')[0]
    navigator.clipboard.writeText(currentUrl)
  }

  // Build report bug modal
  const inputRef = useRef(null)
  const modalReportBug = (
    <Modal
      isShown={showBugReportModal}
      onClose={() => setShowBugReportModal(false)}
      title="Report Bug"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
      initialFocusRef={inputRef}
    >
      <ReportBugForm inputRef={inputRef} />
    </Modal>
  )

  return (
    <div className="page-actions">
      <Tooltip placement="top" content={<>Copy&nbsp;URL</>}>
        <IconButton
          variant="secondary"
          className="page-actions__button page-actions__button--copy"
          icon={<LinkIcon size="small" />}
          onClick={handleCopyUrl}
        />
      </Tooltip>
      <Tooltip placement="top" content={<>Report&nbsp;Bug</>}>
        <IconButton
          variant="secondary"
          className="page-actions__button page-actions__button--report"
          icon={<IconBug />}
          onClick={handleBugReport}
        />
      </Tooltip>
      {modalReportBug}
      <div className={`copy-note ${showText ? 'show' : ''}`}>URL Copied!</div>
    </div>
  )
}

export default PageActions