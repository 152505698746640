import { randomString } from 'library/helpers/utilities'
import { generateEntryLink, organizeAssociations } from 'library/helpers/content'

const IndexList = ({ entries, id, expand = false, organize = true, deepLinks = false }) => {

  // Organize lists
  if (organize) entries = organizeAssociations(entries)

  let links = []
  for (const entry of entries) {

    // Expand link details
    let details = []
    if (expand) {
      if (entry.sys.contentType.sys.id === 'armyListEntry' && entry.fields.unitCategory) {
        for (const category of entry.fields.unitCategory) {
          if (category.fields && category.fields.name) {
            details.push(<li key={category.sys.id}>{category.fields.name}</li>)
          }
        }
      }
      if (entry.sys.contentType.sys.id === 'unit' && entry.fields.unitCategory) {
        for (const category of entry.fields.unitCategory) {
          if (category.fields && category.fields.name) {
            details.push(<li key={category.sys.id}>{category.fields.name}</li>)
          }
        }
      }
      if (entry.fields.ruleType) {
        for (const type of entry.fields.ruleType) {
          if (type.fields && type.fields.name) {
            details.push(<li key={type.sys.id}>{type.fields.name}</li>)
          }
        }
      }
      if (entry.fields.magicLore) {
        for (const lore of entry.fields.magicLore) {
          if (lore.fields && lore.fields.name) {
            let name = lore.fields.name
            if (lore.fields.name.substr(lore.fields.name.length - 5).toLowerCase() === ' lore') name = name.slice(0, -5)
            details.push(<li key={lore.sys.id}>{name}</li>)
          }
        }
      }
      if (entry.fields.magicItemType) {
        for (const type of entry.fields.magicItemType) {
          if (type.fields && type.fields.name) {
            let name = type.fields.name
            if (type.fields.name.substr(type.fields.name.length - 5).toLowerCase() === ' type') name = name.slice(0, -5)
            details.push(<li key={type.sys.id}>{name}</li>)
          }
        }
      }
      if (entry.fields.association) {
        for (const association of entry.fields.association) {
          if (association.fields.hidden) continue // Skip hidden
          if (association.fields && association.fields.name) {
            details.push(<li key={association.sys.id}>{association.fields.name}</li>)
          }
        }
      }
      if (entry.sys.contentType.sys.id === 'ruleType') {
        const mainRulebookName = process.env.NEXT_PUBLIC_MAIN_RULEBOOK_NAME ? process.env.NEXT_PUBLIC_MAIN_RULEBOOK_NAME : 'Main Rulebook'
        details.push(<li key="ruleType">{mainRulebookName}</li>)
      }
    }

    // Build link, configure
    const id = entry.id ? entry.id : entry.sys.id
    const name = entry.name ? entry.name : entry.fields.name
    links.push(
      <li key={id}>
        {generateEntryLink(entry, name, deepLinks)}
        {expand && details.length > 0 ? <ul className="breadcrumb">{details}</ul> : null}
      </li>
    )
  }

  return (
    <ul className={`index-list ${expand ? 'index-list--expanded' : ''}`} id={id ? id : ''}>
      {links}
    </ul>
  )
}

export default IndexList